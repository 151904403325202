exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-de-imprint-mdx": () => import("./../../../src/pages/{Mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/de/imprint.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-de-imprint-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-de-privacy-mdx": () => import("./../../../src/pages/{Mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/de/privacy.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-de-privacy-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-imprint-mdx": () => import("./../../../src/pages/{Mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/imprint.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-imprint-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-privacy-mdx": () => import("./../../../src/pages/{Mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/privacy.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-src-content-privacy-mdx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */)
}

